import logo from "./icons/LOGO.svg";
import todoListIcon from "./icons/todo-list-icon.svg";
import helpDeskIcon from "./icons/help-desk-icon.svg";
import analyticsIcon from "./icons/analytics-icon.svg";
import settingsIcon from "./icons/settings-icon.svg";
import messagesIcon from "./icons/messages-icon.svg";
import logOutIcon from "./icons/log-out.svg";
import plusCircleIcon from "./icons/plus-circle.svg";
import userCircleIcon from "./icons/user-circle.svg";
import commentBlockIcon from "./icons/comment-block.svg";
import inbox from "./icons/inbox.svg";
import assigned from "./icons/assigned.svg";
import hide from "./icons/hide.svg";
import categories from "./icons/fi_grid.svg";
import commentuser from "./icons/commentuser.svg";
import edit from "./icons/edit.svg";
import filledLogo from "./icons/filledLogo.svg";
import fileedit from "./icons/file-edit.svg";
import draft from "./icons/draft.svg";
import resendIcon from "./icons/resend-icon.svg";
import OperatorProfileImage from "./icons/Profile-Image.svg";
import paperclip from "./icons/paperclip.svg";
import commentquestion from "./icons/comment-question.svg";
import eye from "./icons/eye.svg";
import file from "./icons/file.svg";
import vector from "./icons/vector.svg";
import EditOperatorIcon from "./icons/fi_edit-3.svg";
import DeleteOperatorIcon from "./icons/fi_trash-2.svg";
import grid from "./icons/grid.svg";
import Close from "./img/Close.png";
import location from "./icons/location.svg";
import pack from "./icons/package.svg";
import account from "./icons/account.svg";
import helpdesk from "./icons/helpdesk.svg";
import users from "./icons/users.svg";
import notification from "./icons/notification.svg";
import shortcutmessages from "./icons/shortcut-messages.svg";
import MoreVertIcon from "./icons/MoreVertIcon.svg";
import addIcon from "./img/add-icon.png";
import smileIcon from "./icons/smile-icon.svg";
import chooseFile from "./icons/choose-file-icon.svg";
import perfect from "./img/perfect.png";
import photoIcon from "./icons/photo-icon.svg";
import openusers from "./icons/fi_users.svg";
import equivalent from "./icons/equivalent.svg";
import search from "./icons/search.svg";
import checkCircleIcon from "./icons/check-circle.svg";
import chakkebAlBacIcon from "./icons/chakkeb-al-bac-icon.svg";
import mloulLelekherIcon from "./icons/mloul-lelekher-icon.svg";
import silverIcon from "./icons/silver-icon.svg";
import googleIcon from "./icons/google-icon.svg";
import ipIcon from "./icons/ip-icon.svg";
import clock from "./icons/u_clock-seven.svg";
import close from "./icons/close.svg";
import sendIcon from "./icons/send-icon.svg";
import arrowRightIcon from "./icons/arrow-right-icon.svg";
import messagera from "./icons/poste-tunis-icon.gif";
import assignIcon from "./icons/assign-icon.svg";
import searchIcon from "./icons/search-icon.svg";
import unresolvedIcon from "./icons/unresolved-icon.svg";
import editIcon from "./icons/edit-icon.svg";
import calendar from "./icons/fi_calendar.svg";
import envelopeIcon from "./icons/envelope-icon.svg";
import clipboardIcon from "./icons/clipboard-icon.svg";
import trashIcon from "./icons/trash-icon.svg";
import warning from "./icons/warning.svg";
import deleteIcon from "./icons/delete-icon.svg";
import muteIcon from "./icons/mute-icon.svg";
import profileImage from "./icons/profileImage.svg";
import comment from "./icons/comment.svg";
import locationArrowIcon from "./icons/location-arrow-icon.svg";
import trashModalIcon from "./icons/trash-modal-icon.svg";
import deleteConvIcon from "./icons/delete-conv-icon.svg";
import dateIcon from "./icons/date-icon.svg";
import timeIcon from "./icons/time-icon.svg";
import Shared from "./img/share.png";
import Bot from "./img/Bot.png";
import alertIcon from "./icons/alert-icon.svg";
import revoke from "./icons/revoke.svg";
import checkCircleWhiteIcon from "./icons/check-circle-white-icon.svg";
import plusCircleArticleIcon from "./icons/plus-circle-article-icon.svg";
import pointDraftIcon from "./icons/point-icon.svg";
import eyeIcon from "./icons/eye-icon.svg";
import pointVisibleIcon from "./icons/point-visible-icon.svg";
import operator from "./icons/operator.svg";
import banUserIcon from "./icons/ban-user-icon.svg";
import chatBotIcon from "./icons/chat-bot-icon.svg";
import takiChatText from "./icons/taki-chat-text.svg";
import publishIcon from "./icons/publishIcon.svg";
import confirmationAccount from "./img/confirmation-account.svg";
import ArrowBottom from "./icons/ArrowBottom.svg";
import AddArticleImg from "./icons/AddArticle.svg";
import More from "./icons/More.svg";
import safariIcon from "./icons/safari.png";
import firefoxIcon from "./icons/firefox.png";
import operaIcon from "./icons/opera.png";
import edgeIcon from "./icons/edge.png";
import osIcon from "./icons/os.png";
import openIcon from "./icons/open.svg";
import clients from "./img/1543.9-clients-icon-iconbunny-removebg-preview.png";
import technicalSupport from "./img/technical-support.png";
import bellIcon from "./icons/bell-icon.svg";
import plusIcon from "./icons/plus-icon.svg";
import ar from "./icons/ar.svg";
import fr from "./icons/fr.svg";
import en from "./icons/en.svg";
import archiveIcon from "./icons/archive-icon.svg";
import departmentIcon from "./img/department-icon.png";
import colorSetting from "./icons/color-setting.png";
import chatBoxLogo from "./icons/chatboxlogo.png";
import departmentsIcon from "./icons/department-icon.png";
import angryFace from "./icons/coloredAngryFace.svg";
import disappointedFace from "./icons/coloredDisappointed.svg";
import expressionLessFace from "./icons/coloredExpressionlessFace.svg";
import grinningFace from "./icons/coloredGrinningFace.svg";
import smilingFace from "./icons/coloredSmilingFace.svg";
import searchSidebarIcon from "./icons/search-icon (1).svg";
import botConfigIcon from "./icons/bot-config-icon.png";
import websiteConfig from "./icons/website-config.png";
import veryGoodSmileIcon from "./icons/veryGoodSmile.svg";
import goodSmileIcon from "./icons/goodSmile.svg";
import averageSmileIcon from "./icons/averageSmile.svg";
import badSmileIcon from "./icons/badSmile.svg";
import veryBadSmileIcon from "./icons/veryBadSmile.svg";
import arrowBottomStatIcon from "./icons/arrow-down.svg";
import arrowUpStatIcon from "./icons/arrow-up.svg";

export {
  ar,
  websiteConfig,
  fr,
  en,
  logo,
  account,
  technicalSupport,
  searchSidebarIcon,
  clients,
  operator,
  location,
  ArrowBottom,
  More,
  AddArticleImg,
  helpdesk,
  Shared,
  profileImage,
  search,
  hide,
  filledLogo,
  categories,
  draft,
  inbox,
  grid,
  close,
  assigned,
  commentuser,
  eye,
  perfect,
  paperclip,
  vector,
  edit,
  commentquestion,
  Close,
  EditOperatorIcon,
  DeleteOperatorIcon,
  pack,
  notification,
  revoke,
  file,
  Bot,
  messagera,
  users,
  shortcutmessages,
  clock,
  warning,
  OperatorProfileImage,
  todoListIcon,
  helpDeskIcon,
  fileedit,
  analyticsIcon,
  settingsIcon,
  messagesIcon,
  logOutIcon,
  plusCircleIcon,
  userCircleIcon,
  commentBlockIcon,
  MoreVertIcon,
  openusers,
  addIcon,
  smileIcon,
  chooseFile,
  photoIcon,
  checkCircleIcon,
  equivalent,
  chakkebAlBacIcon,
  mloulLelekherIcon,
  silverIcon,
  googleIcon,
  ipIcon,
  sendIcon,
  arrowRightIcon,
  assignIcon,
  searchIcon,
  comment,
  calendar,
  unresolvedIcon,
  editIcon,
  envelopeIcon,
  clipboardIcon,
  trashIcon,
  deleteIcon,
  muteIcon,
  locationArrowIcon,
  trashModalIcon,
  deleteConvIcon,
  dateIcon,
  timeIcon,
  alertIcon,
  checkCircleWhiteIcon,
  plusCircleArticleIcon,
  pointDraftIcon,
  eyeIcon,
  pointVisibleIcon,
  banUserIcon,
  chatBotIcon,
  takiChatText,
  publishIcon,
  confirmationAccount,
  resendIcon,
  safariIcon,
  firefoxIcon,
  operaIcon,
  edgeIcon,
  osIcon,
  openIcon,
  bellIcon,
  plusIcon,
  archiveIcon,
  departmentIcon,
  colorSetting,
  chatBoxLogo,
  departmentsIcon,
  angryFace,
  disappointedFace,
  expressionLessFace,
  grinningFace,
  smilingFace,
  botConfigIcon,
  veryGoodSmileIcon,
  goodSmileIcon,
  averageSmileIcon,
  badSmileIcon,
  veryBadSmileIcon,
  arrowBottomStatIcon,
  arrowUpStatIcon,
};
